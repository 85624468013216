import { createFetch } from '@vueuse/core';
import useErrorToasts from '@/composables/useErrorToasts';
import { USER_API } from '@/config/appConfig';
import { computed } from 'vue';

const userFetch = createFetch({
  baseUrl: USER_API,
  options: {
    async onFetchError(payload) {
      useErrorToasts(payload);
      return payload;
    },
  },
  fetchOptions: {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  },
});

export default () => ({
  getAuth: (options) => userFetch('info', options).get().json(),

  /**
   * Disconnect a social network from user's account.
   * @param {Number} userId - ID of the user with the account connection.
   * @param {Number|String} payload.id - ID of the account connection.
   * @param {String} payload.network - Name of the network.
   */
  disconnectNetwork: (userId, payload) => {
    if (!payload) {
      throw new Error('Missing required `payload` param.');
    }

    switch (payload.network) {
      // Twitter and Facebook use /disconnect
      case 'twitter':
      case 'facebook': {
        return userFetch('disconnect').post(payload).json();
      }

      // Google uses DELETE /google/accounts/*
      case 'google': {
        const accountId = `google.${payload.id}`;
        const path = `google/accounts/${userId}?accountId=${accountId}`;
        return userFetch(path).delete().json();
      }

      default: {
        throw new Error('Invalid `payload.network` provided to `disconnectNetwork()`');
      }
    }
  },

  /**
   * Link facebook page to user's account.
   * @param {Number} userId - ID of the account owner.
   * @param {String} pageId - ID of the facebook page to connect.
   * @returns {Promise<Object>}
   */
  connectFacebookPage: (userId, pageId) => (
    userFetch(`facebook/page/connect/${userId}`)
      .post({ page_id: pageId })
      .json()
  ),

  /**
   * Remove existing facebook page connection from user's account.
   * Only removes from `facebook_pages` map,
   * and can be reconnected without reconnecting Facebook account.
   * @param {Number} userId - ID of the account owner.
   * @param {String} pageId - ID of the facebook page to disconnect.
   * @returns {Promise<Object>}
   */
  disconnectFacebookPage: (userId, pageId) => (
    userFetch(`facebook/page/disconnect/${userId}`)
      .delete({ page_id: pageId })
      .json()
  ),

  /**
   * Get API key information for the user and accounts they can represent.
   * @param {Number} owner - ID of the user.
   * @param {Array} teams - Array of team IDs the user can represent.
   * @returns {Promise<Object[]>}
   */
  getApiKeysInfo: (userId) => (
    userFetch(`api-keys/${userId}`)
      .get()
      .json()
  ),

  /**
   * Removes a specified API key associated with a user and application.
   * @param {Number} userId - The ID of the user whose API key is to be removed.
   * @param {String} appName - The name of the app associated with the API key (eg. sportRadar).
   * @param {String} id - Id of the API key to be removed.
   * @returns {Promise<Boolean>} - True if the API key was successfully removed.
   */
  removeApiKey: (userId, appName, id, options = {}) => (
    userFetch(`api-keys/${userId}`, options)
      .delete({ appName, id })
      .json()
  ),

  editUserInfo: (userId, data, options) => {
    const url = computed(() => `auth/user/${userId.value}`);

    return userFetch(url, options).put(data).json();
  },

  enableMFA: (userId, options) => {
    const url = computed(() => `auth/mfa/${userId}/enable`);

    return userFetch(url, options).put().json();
  },

  disableMFA: (userId, options) => {
    const url = computed(() => `auth/mfa/${userId}/disable`);

    return userFetch(url, options).put().json();
  },

  resetPassword: (userId, options) => {
    const url = computed(() => `auth/${userId}/reset-password`);

    return userFetch(url, options).post().json();
  },

  emailChangeInfo: (userId, options = {}) => {
    const url = `/email-change/owner/${userId}`;
    return userFetch(url, options).get();
  },

  emailChangeReq: (payload, options = {}) => {
    const url = '/email-change';

    return userFetch(url, options).post(payload).json();
  },

  resendEmailChangeReq: (userId, options) => {
    const url = `/email-change/resend/${userId}`;

    return userFetch(url, options).get().json();
  },

  cancelEmailUpdate: (userId, options) => {
    const url = computed(() => `email-change/owner/${userId}`);

    return userFetch(url, options).delete().json();
  },

  getEmailChangeKeyInfo: (emailKey, options = {}) => {
    const url = `/email-change/key/${emailKey}`;
    return userFetch(url, options).get().json();
  },

  confirmEmailChange: (emailKey, options = {}) => {
    const url = `/email-change/${emailKey}/confirmed`;
    return userFetch(url, options).get().json();
  },
});
