import { ref } from 'vue';
import Pusher from 'pusher-js/with-encryption';
import useAdobeService from '@/services/adobe';
import { PUSHER_KEY, PUSHER_CLUSTER } from '@/config/appConfig';

/* ***** User Channels ***** */

const pusherInstance = ref(null); // Generic connection

function initPusher() {
  pusherInstance.value = new Pusher(PUSHER_KEY, { cluster: PUSHER_CLUSTER });
  return pusherInstance.value;
}

function subscribeToUserChannel(userId) {
  if (!userId) return undefined;
  if (!pusherInstance.value) initPusher();
  return pusherInstance.value.subscribe(`user-${userId}`);
}

function unsubscribeFromUserChannel(userId) {
  if (!userId) return undefined;
  if (!pusherInstance.value) initPusher();
  return pusherInstance.value.unsubscribe(`user-${userId}`);
}

/* ***** Adobe Channels ***** */

const pusherInstanceAdobe = ref(null); // Authed connection for encrypted Adobe notifications.

const adobeCustomHandler = (userId) => async ({ channelName, socketId }, cb) => {
  const pusherPayload = {
    userId,
    channelName,
    socketId,
  };

  const {
    data: pusherAuthData,
    error: pusherAuthError,
  } = await useAdobeService().makePusherAuth(pusherPayload);
  cb(pusherAuthError.value, pusherAuthData.value);
};

function initAdobePusher(userId) {
  const customHandler = adobeCustomHandler(userId);

  pusherInstanceAdobe.value = new Pusher(PUSHER_KEY, {
    cluster: PUSHER_CLUSTER,
    enabledTransports: ['wss', 'ws', 'xhr_streaming'],
    channelAuthorization: { customHandler },
    userAuthentication: { customHandler },
  });

  return pusherInstanceAdobe.value.signin();
}

function subscribeToAdobeNotifications(userId) {
  if (!userId) return undefined;
  return pusherInstanceAdobe.value.subscribe(`private-encrypted-notifications_${userId}`);
}

function unsubscribeFromAdobeNotifications(userId) {
  if (!userId) return undefined;
  return pusherInstanceAdobe.value.unsubscribe(`private-encrypted-notifications_${userId}`);
}

/* ***** Exports ***** */

export default () => {
  const init = async (userId) => {
    const user = initPusher();
    const adobe = await initAdobePusher(userId);

    return {
      adobe,
      user,
    };
  };

  return {
    instances: {
      adobe: pusherInstanceAdobe?.value || pusherInstanceAdobe,
      user: pusherInstance?.value || pusherInstance,
    },
    init,
    subscribeToAdobeNotifications,
    unsubscribeFromAdobeNotifications,
    subscribeToUserChannel,
    unsubscribeFromUserChannel,
  };
};
