<script setup>

import { ref, onMounted, nextTick } from 'vue';
import { Vue3Lottie } from 'vue3-lottie';

import hashyLoader from '../assets/lottie/hashyAnimation.json';

const isLoaded = ref(false);

onMounted(() => {
  nextTick(() => {
    // add a delay to simulate loading
    setTimeout(() => {
      isLoaded.value = true;
    }, 2000);
  });
});

</script>

<template>
  <Transition name="fade">
    <div class="page-loader flex flex-column" v-if="!isLoaded" data-entity="app-pageLoader">
      <Vue3Lottie :animationData="hashyLoader" :height="150" :width="150" />
      <h3>Something amazing awaits you...</h3>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.page-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: white;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  color: #333;

  .dark-theme & {
    background-color: var(--dark-color);
    color: white;
  }
}

 /* Initial state for entering */
 .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

  /* Target state for entering */
  .fade-enter-to, .fade-leave-from {
    opacity: 1;
  }

  /* Transition */
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease-in-out;
  }
</style>
